@tailwind base;
@tailwind components;

@font-face {
    font-family: 'Poppins';
    font-weight: 400;
    src: url(/assets/fonts/poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: 'Poppins';
    font-weight: 600;
    src: url(/assets/fonts/poppins/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: url(/assets/fonts/material-icons/MaterialIconsOutlined-Regular.otf); 
}

.smb-icon {
    font-family: 'Material Icons Outlined' !important;
    font-weight: normal !important;
    font-style: normal !important;
    font-size: 24px !important; /* Preferred icon size */
    display: inline-block !important;
    line-height: 1 !important;
    text-transform: none !important;
    letter-spacing: normal !important;
    word-wrap: normal !important;
    white-space: nowrap !important;
    direction: ltr !important;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased !important;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility !important;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale !important;

    /* Support for IE. */
    font-feature-settings: 'liga' !important;

    /* Disable user selection */
    -ms-user-select: none !important;
    -moz-user-select: none !important;
    -webkit-user-select: none !important;
    -webkit-touch-callout: none !important;
    -khtml-user-select: none !important;
    user-select: none !important;
}

@tailwind utilities;

/*
 * Pace.js styles
 */
.pace {
    -webkit-pointer-events: none;
    pointer-events: none;

    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.pace-inactive {
    display: none;
}

.pace .pace-progress {
    background: #fff;
    position: fixed;
    z-index: 2000;
    top: 0;
    right: 100%;
    width: 100%;
    height: 0.5rem;
}
