*, ::before, ::after {
  border-color: currentColor;
}

@font-face {
  font-family: 'Poppins';

  font-weight: 400;

  src: url(/assets/fonts/poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins';

  font-weight: 600;

  src: url(/assets/fonts/poppins/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: 'Material Icons Outlined';

  font-style: normal;

  font-weight: 400;

  src: url(/assets/fonts/material-icons/MaterialIconsOutlined-Regular.otf);
}

.smb-icon {
  font-family: 'Material Icons Outlined' !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-size: 24px !important; /* Preferred icon size */
  display: inline-block !important;
  line-height: 1 !important;
  text-transform: none !important;
  letter-spacing: normal !important;
  word-wrap: normal !important;
  white-space: nowrap !important;
  direction: ltr !important;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased !important;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility !important;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale !important;
  /* Support for IE. */
  font-feature-settings: 'liga' !important;
  /* Disable user selection */
  -ms-user-select: none !important;
  -moz-user-select: none !important;
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
  user-select: none !important;
}

.tw-fixed {
  position: fixed !important;
}

.tw-relative {
  position: relative !important;
}

.tw-inset-0 {
  top: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
}

.tw-z-10 {
  z-index: 10 !important;
}

.tw-float-left {
  float: left !important;
}

.tw-m-0 {
  margin: 0px !important;
}

.tw-m-2 {
  margin: 0.5rem !important;
}

.tw-m-auto {
  margin: auto !important;
}

.tw-mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.tw-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.tw-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.tw-mt-1 {
  margin-top: 0.25rem !important;
}

.tw-mt-8 {
  margin-top: 2rem !important;
}

.tw-mr-2 {
  margin-right: 0.5rem !important;
}

.tw-mb-1 {
  margin-bottom: 0.25rem !important;
}

.tw-ml-2 {
  margin-left: 0.5rem !important;
}

.tw-block {
  display: block !important;
}

.tw-flex {
  display: flex !important;
}

.tw-grid {
  display: grid !important;
}

.tw-hidden {
  display: none !important;
}

.tw-h-3 {
  height: 0.75rem !important;
}

.tw-h-6 {
  height: 1.5rem !important;
}

.tw-h-16 {
  height: 4rem !important;
}

.tw-h-20 {
  height: 5rem !important;
}

.tw-h-auto {
  height: auto !important;
}

.tw-h-3\.5 {
  height: 0.875rem !important;
}

.tw-h-2\/3 {
  height: 66.666667% !important;
}

.tw-h-full {
  height: 100% !important;
}

.tw-max-h-60 {
  max-height: 15rem !important;
}

.tw-min-h-screen {
  min-height: 100vh !important;
}

.tw-w-6 {
  width: 1.5rem !important;
}

.tw-w-32 {
  width: 8rem !important;
}

.tw-w-auto {
  width: auto !important;
}

.tw-w-2\/4 {
  width: 50% !important;
}

.tw-w-full {
  width: 100% !important;
}

.tw-flex-shrink-0 {
  flex-shrink: 0 !important;
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes tw-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes tw-pulse {
  50% {
    opacity: .5;
  }
}

@keyframes tw-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.tw-cursor-pointer {
  cursor: pointer !important;
}

.tw-cursor-not-allowed {
  cursor: not-allowed !important;
}

.disabled\:tw-cursor-not-allowed:disabled {
  cursor: not-allowed !important;
}

.tw-appearance-none {
  -webkit-appearance: none !important;
          appearance: none !important;
}

.tw-flex-row {
  flex-direction: row !important;
}

.tw-flex-col {
  flex-direction: column !important;
}

.tw-flex-wrap {
  flex-wrap: wrap !important;
}

.tw-place-items-center {
  place-items: center !important;
}

.tw-items-start {
  align-items: flex-start !important;
}

.tw-items-end {
  align-items: flex-end !important;
}

.tw-items-center {
  align-items: center !important;
}

.tw-justify-start {
  justify-content: flex-start !important;
}

.tw-justify-center {
  justify-content: center !important;
}

.tw-justify-between {
  justify-content: space-between !important;
}

.tw-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}

.tw-overflow-auto {
  overflow: auto !important;
}

.tw-overflow-y-auto {
  overflow-y: auto !important;
}

.tw-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.tw-rounded {
  border-radius: 4px !important;
}

.tw-rounded-sm {
  border-radius: 6px !important;
}

.tw-rounded-md {
  border-radius: 8px !important;
}

.tw-rounded-full {
  border-radius: 9999px !important;
}

.tw-rounded-r {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.tw-rounded-l {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.tw-border-0 {
  border-width: 0px !important;
}

.tw-border {
  border-width: 1px !important;
}

.tw-border-r-0 {
  border-right-width: 0px !important;
}

.tw-border-b {
  border-bottom-width: 1px !important;
}

.tw-border-l-0 {
  border-left-width: 0px !important;
}

.tw-border-solid {
  border-style: solid !important;
}

.tw-border-smoke-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(175, 177, 178, var(--tw-border-opacity)) !important;
}

.tw-border-smoke-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(56, 60, 62, var(--tw-border-opacity)) !important;
}

.tw-border-lime-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(56, 155, 15, var(--tw-border-opacity)) !important;
}

.tw-border-red-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgba(165, 24, 24, var(--tw-border-opacity)) !important;
}

.hover\:tw-border-smoke-500:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgba(56, 60, 62, var(--tw-border-opacity)) !important;
}

.tw-bg-primary-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(80, 192, 210, var(--tw-bg-opacity)) !important;
}

.tw-bg-primary-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 165, 191, var(--tw-bg-opacity)) !important;
}

.tw-bg-primary-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(4, 124, 143, var(--tw-bg-opacity)) !important;
}

.tw-bg-primary-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(3, 99, 115, var(--tw-bg-opacity)) !important;
}

.tw-bg-smoke-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(235, 236, 236, var(--tw-bg-opacity)) !important;
}

.tw-bg-smoke-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(175, 177, 178, var(--tw-bg-opacity)) !important;
}

.tw-bg-smoke-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(56, 60, 62, var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(74, 207, 20, var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(67, 186, 18, var(--tw-bg-opacity)) !important;
}

.tw-bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(220, 32, 32, var(--tw-bg-opacity)) !important;
}

.tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.hover\:tw-bg-primary-500:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(5, 165, 191, var(--tw-bg-opacity)) !important;
}

.hover\:tw-bg-smoke-100:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(235, 236, 236, var(--tw-bg-opacity)) !important;
}

.hover\:tw-bg-smoke-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(116, 119, 120, var(--tw-bg-opacity)) !important;
}

.hover\:tw-bg-lime-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(128, 221, 91, var(--tw-bg-opacity)) !important;
}

.hover\:tw-bg-red-400:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(231, 99, 99, var(--tw-bg-opacity)) !important;
}

.disabled\:tw-bg-white:disabled {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.tw-bg-clip-padding {
  background-clip: padding-box !important;
}

.tw-bg-no-repeat {
  background-repeat: no-repeat !important;
}

.tw-fill-current {
  fill: currentColor !important;
}

.tw-object-cover {
  object-fit: cover !important;
}

.tw-p-0 {
  padding: 0px !important;
}

.tw-p-2 {
  padding: 0.5rem !important;
}

.tw-p-3 {
  padding: 0.75rem !important;
}

.tw-p-4 {
  padding: 1rem !important;
}

.tw-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.tw-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.tw-px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.tw-px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.tw-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.tw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.tw-py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.tw-py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.tw-pt-6 {
  padding-top: 1.5rem !important;
}

.tw-pt-36 {
  padding-top: 9rem !important;
}

.tw-pb-5 {
  padding-bottom: 1.25rem !important;
}

.tw-pb-6 {
  padding-bottom: 1.5rem !important;
}

.tw-font-primary {
  font-family: Poppins, sans-serif !important;
}

.tw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.tw-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.tw-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.tw-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.tw-font-regular {
  font-weight: 400 !important;
}

.tw-font-semibold {
  font-weight: 600 !important;
}

.tw-text-primary-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(5, 165, 191, var(--tw-text-opacity)) !important;
}

.tw-text-primary-700 {
  --tw-text-opacity: 1 !important;
  color: rgba(4, 124, 143, var(--tw-text-opacity)) !important;
}

.tw-text-smoke-50 {
  --tw-text-opacity: 1 !important;
  color: rgba(245, 245, 245, var(--tw-text-opacity)) !important;
}

.tw-text-smoke-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(56, 60, 62, var(--tw-text-opacity)) !important;
}

.tw-text-smoke-900 {
  --tw-text-opacity: 1 !important;
  color: rgba(27, 29, 30, var(--tw-text-opacity)) !important;
}

.tw-text-link-500 {
  --tw-text-opacity: 1 !important;
  color: rgba(61, 52, 201, var(--tw-text-opacity)) !important;
}

.tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.tw-text-black {
  --tw-text-opacity: 1 !important;
  color: rgba(0, 0, 0, var(--tw-text-opacity)) !important;
}

.hover\:tw-text-smoke-100:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(235, 236, 236, var(--tw-text-opacity)) !important;
}

.hover\:tw-text-link-400:hover {
  --tw-text-opacity: 1 !important;
  color: rgba(119, 113, 217, var(--tw-text-opacity)) !important;
}

.disabled\:tw-text-smoke-200:disabled {
  --tw-text-opacity: 1 !important;
  color: rgba(205, 206, 207, var(--tw-text-opacity)) !important;
}

.disabled\:tw-text-smoke-300:disabled {
  --tw-text-opacity: 1 !important;
  color: rgba(175, 177, 178, var(--tw-text-opacity)) !important;
}

.tw-underline {
  text-decoration: underline !important;
}

.tw-opacity-30 {
  opacity: 0.3 !important;
}

*, ::before, ::after {
  --tw-shadow: 0 0 #0000;
}

.tw-shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

*, ::before, ::after {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.tw-transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.tw-line-clamp-1 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}

.tw-line-clamp-2 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
}

.tw-line-clamp-3 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
}

/*
 * Pace.js styles
 */

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #fff;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 0.5rem;
}

@media (min-width: 768px) {
  .sm\:tw-m-0 {
    margin: 0px !important;
  }

  .sm\:tw-mx-8 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }

  .sm\:tw-my-4 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .sm\:tw-mb-2 {
    margin-bottom: 0.5rem !important;
  }

  .sm\:tw-h-7 {
    height: 1.75rem !important;
  }

  .sm\:tw-h-12 {
    height: 3rem !important;
  }

  .sm\:tw-h-40 {
    height: 10rem !important;
  }

  .sm\:tw-w-12 {
    width: 3rem !important;
  }

  .sm\:tw-w-64 {
    width: 16rem !important;
  }

  .sm\:tw-flex-row {
    flex-direction: row !important;
  }

  .sm\:tw-items-start {
    align-items: flex-start !important;
  }

  .sm\:tw-justify-between {
    justify-content: space-between !important;
  }

  .sm\:tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .sm\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
  }

  .sm\:tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
  }

  .sm\:tw-rounded-sm {
    border-radius: 6px !important;
  }

  .sm\:tw-rounded-md {
    border-radius: 8px !important;
  }

  .sm\:tw-rounded-r-sm {
    border-top-right-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
  }

  .sm\:tw-rounded-l-sm {
    border-top-left-radius: 6px !important;
    border-bottom-left-radius: 6px !important;
  }

  .sm\:tw-px-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .sm\:tw-px-8 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .sm\:tw-px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .sm\:tw-px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .sm\:tw-py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .sm\:tw-py-4 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .sm\:tw-text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .sm\:tw-text-xl {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }

  .sm\:tw-text-2xl {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  .sm\:tw-text-3xl {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  .sm\:tw-line-clamp-3 {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 3 !important;
  }
}

@media (min-width: 1024px) {
  .md\:tw-mx-12 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }

  .md\:tw-flex {
    display: flex !important;
  }

  .md\:tw-hidden {
    display: none !important;
  }

  .md\:tw-flex-col {
    flex-direction: column !important;
  }

  .md\:tw-items-start {
    align-items: flex-start !important;
  }

  .md\:tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
  }

  .md\:tw-rounded-full {
    border-radius: 9999px !important;
  }

  .md\:tw-px-12 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  .md\:tw-py-6 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .md\:tw-text-lg {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  .md\:tw-line-clamp-2 {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-box-orient: vertical !important;
    -webkit-line-clamp: 2 !important;
  }
}

@media (min-width: 1536px) {
  .lg\:tw-mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .lg\:tw-w-3\/5 {
    width: 60% !important;
  }

  .lg\:tw-px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  .lg\:tw-py-8 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
}

/* purgecss start ignore */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

:root{
  --azul_sambay:#04aac0;
}

.App-header {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* purgecss end ignore */

/* purgecss start ignore */

.FilterOptionItem_title__zXSAK{
  font-family: 'Poppins';
  font-size: 15px;
  margin-left: 8px;
  color:black;
}

.FilterOptionItem_buttonSelect__2ckgA{
  width: 40px;
  height: 20px;
}

/* purgecss end ignore */
/* purgecss start ignore */

.styles_container__34N6t {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
}

.styles_container_search__3ZzWD {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  flex-flow: row wrap;
  margin-top: 1rem;
  grid-column-gap: 1.563rem;
  column-gap: 1.563rem;
}

.styles_search_title__2Zf5x {
  font-family: 'Poppins', semi-bold;
  font-weight: 600;
  color: #6b98a6;
  font-size: 1.5rem;
}

.styles_serch__mty3Y {
  height: 3rem;
  width: 45rem;
  border: 1px solid #00000029;
  border-radius: 12px;
}

.styles_serch__mty3Y:focus {
  outline: none;
  border: 2px solid var(--azul_sambay);
}

.styles_container_itens__eJUwA {
  margin-top: 6rem;
  min-height: 100%;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
}

.styles_item__2sps6 {
  flex-grow: 1;
  max-width: 17%;
  height: auto;
  margin: 6px 6px;
  border-radius: 12px;
}

.styles_container_button_viewMore__MQs3P {
  margin-top: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.styles_button_view__16Uf_ {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--azul_sambay);
  border: 1px solid black;
  width: 11vw;
  height: 5vh;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  font-family: 'Poppins', semi-bold;
}

/* purgecss end ignore */

/*
purgecss start ignore
*/

/*
Edit Banner Component
*/

.styles_container__N8qWy {
  display: flex;
  margin: 10px;
  flex-direction: column;
}

.styles_content__3vG6X {
  width: 100%;
  height: 100%;
  max-width: 400px;
  margin: 30px;
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  justify-self: center;
  align-self: center;
  height: 150;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.styles_title__3RiMW {
  font-family: 'Poppins', semi-bold;
  font-weight: 600;
  color: #6b98a6;
  font-size: 2.3rem;
}

.styles_subtitle__4zEd9 {
  font-family: 'Poppins', semi-bold;
  font-weight: 400;
  font-size: 1.2em;
  color: #6b98a6;
}

/*
Upload Component
*/

.styles_dropzone__1woKH {
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
}

.styles_dragActive__1VR1Q {
  border-color: #78e5d5;
}

.styles_dragReject__1isOp {
  border-color: #e57878;
}

.styles_inputZone__2B1pI {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  color: #999;
}

.styles_error__1TFNS {
  color: #e57878;
}

.styles_success__3rePq {
  color: #78e5d5;
}

/* File List */

.styles_containerFileList__Id-3J {
  margin-top: 20px !important;
}

li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #444;
}

.styles_fileInfo__1ghAx {
  display: flex;
  align-items: center;
}

.styles_fileInfo__1ghAx > div {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

.styles_uploaded-file__24h0t {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

button {
  border: 0;
  background: transparent;
  color: #e57878;
  cursor: pointer;
}

.styles_image__ECLPP {
  width: 46px;
  height: 36px;
  border-radius: 5px;
  background-image: url(${this.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

/*
purgecss end ignore
*/

