/*
Edit Banner Component
*/
.container {
	display: flex;
	margin: 10px;
	flex-direction: column;
}
.content {
	width: 100%;
	height: 100%;
	max-width: 400px;
	margin: 30px;
	background-color: #fff;
	border-radius: 4px;
	padding: 20px;
	justify-self: center;
	align-self: center;
	height: 150;
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.title {
	font-family: 'Poppins', semi-bold;
	font-weight: 600;
	color: #6b98a6;
	font-size: 2.3rem;
}
.subtitle {
	font-family: 'Poppins', semi-bold;
	font-weight: 400;
	font-size: 1.2em;
	color: #6b98a6;
}

/*
Upload Component
*/
.dropzone {
	border: 1px dashed #ddd;
	border-radius: 4px;
	cursor: pointer;

	transition: height 0.2s ease;
}
.dragActive {
	border-color: #78e5d5;
}
.dragReject {
	border-color: #e57878;
}
.inputZone {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 15px 0;
	color: #999;
}
.error {
	color: #e57878;
}
.success {
	color: #78e5d5;
}

/* File List */
.containerFileList {
	margin-top: 20px !important;
}
li {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: #444;
}
.fileInfo {
	display: flex;
	align-items: center;
}
.fileInfo > div {
	display: flex;
	flex-direction: column;
	margin-left: 6px;
}
.uploaded-file {
	font-size: 12px;
	color: #999;
	margin-top: 5px;
}
button {
	border: 0;
	background: transparent;
	color: #e57878;
	cursor: pointer;
}
.image {
	width: 46px;
	height: 36px;
	border-radius: 5px;
	background-image: url(${this.src});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
